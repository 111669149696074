import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';

const Terms = () => {
  return (
    <Container>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Terms of Service
        </Typography>
        
        <Typography variant="body2" color="text.secondary" paragraph>
          Last updated: {new Date().toLocaleDateString()}
        </Typography>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography paragraph>
            By accessing and using applications developed by Star Development ("we," "us," or "our"), you agree to be bound by these Terms of Service and all applicable laws and regulations.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            2. Use License
          </Typography>
          <Typography paragraph>
            We grant you a limited, non-exclusive, non-transferable license to use our applications for personal, non-commercial purposes, subject to these Terms.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            3. User Responsibilities
          </Typography>
          <Typography paragraph>
            You agree to:
          </Typography>
          <ul>
            <Typography component="li">Provide accurate and complete information</Typography>
            <Typography component="li">Maintain the security of your account</Typography>
            <Typography component="li">Not use our applications for any illegal purposes</Typography>
            <Typography component="li">Not interfere with or disrupt our services</Typography>
          </ul>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            4. Intellectual Property
          </Typography>
          <Typography paragraph>
            All content, features, and functionality of our applications are owned by Star Development and are protected by international copyright, trademark, and other intellectual property laws.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            5. Disclaimer of Warranties
          </Typography>
          <Typography paragraph>
            Our applications are provided "as is" without any warranties, express or implied. We do not guarantee that our services will be uninterrupted, secure, or error-free.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            6. Limitation of Liability
          </Typography>
          <Typography paragraph>
            Star Development shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use our applications.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            7. Changes to Terms
          </Typography>
          <Typography paragraph>
            We reserve the right to modify these Terms at any time. We will notify users of any material changes by posting the new Terms on our website.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            8. Governing Law
          </Typography>
          <Typography paragraph>
            These Terms shall be governed by and construed in accordance with the laws of California, United States, without regard to its conflict of law provisions.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            9. Contact Information
          </Typography>
          <Typography paragraph>
            For any questions regarding these Terms, please contact us at:
          </Typography>
          <Typography>
            Email: contact@stardev.com
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default Terms; 