import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';

const Privacy = () => {
  return (
    <Container>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        
        <Typography variant="body2" color="text.secondary" paragraph>
          Last updated: {new Date().toLocaleDateString()}
        </Typography>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography paragraph>
            Star Development ("we" or "us") collects information that you provide directly to us when using our applications. This may include:
          </Typography>
          <ul>
            <Typography component="li">Contact information (name, email address)</Typography>
            <Typography component="li">Device information</Typography>
            <Typography component="li">Usage data</Typography>
            <Typography component="li">Any other information you choose to provide</Typography>
          </ul>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            2. How We Use Your Information
          </Typography>
          <Typography paragraph>
            We use the information we collect to:
          </Typography>
          <ul>
            <Typography component="li">Provide, maintain, and improve our applications</Typography>
            <Typography component="li">Communicate with you about our services</Typography>
            <Typography component="li">Monitor and analyze usage patterns</Typography>
            <Typography component="li">Protect against, identify, and prevent fraud and other illegal activities</Typography>
          </ul>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            3. Data Security
          </Typography>
          <Typography paragraph>
            We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            4. Third-Party Services
          </Typography>
          <Typography paragraph>
            Our applications may contain links to third-party websites or services. We are not responsible for the privacy practices of these third parties.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            5. Children's Privacy
          </Typography>
          <Typography paragraph>
            Our services are not directed to children under 13. We do not knowingly collect personal information from children under 13.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            6. Changes to This Policy
          </Typography>
          <Typography paragraph>
            We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            7. Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions about this Privacy Policy, please contact us at:
          </Typography>
          <Typography>
            Email: contact@stardev.com
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default Privacy; 