import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import { Psychology, SmartToy, Security } from '@mui/icons-material';

const Home = () => {
  return (
    <Container>
      <Box sx={{ textAlign: 'center', mb: 8, mt: 6 }}>
        <Typography 
          variant="h2" 
          component="h1" 
          gutterBottom
          sx={{ 
            fontWeight: 700,
            fontSize: { xs: '2.5rem', md: '3.5rem' }
          }}
        >
          Welcome to Star Development
        </Typography>
        <Typography 
          variant="h5" 
          color="text.secondary" 
          paragraph
          sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' } }}
        >
          We create viral apps that make a difference
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 4, textAlign: 'center', height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-5px)' } }}>
            <Psychology sx={{ fontSize: 56, color: 'primary.main', mb: 2 }} />
            <Typography variant="h5" gutterBottom fontWeight="bold">
              Impressive Technology
            </Typography>
            <Typography color="text.secondary">
              Leveraging cutting-edge solutions to create powerful applications that exceed expectations.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 4, textAlign: 'center', height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-5px)' } }}>
            <SmartToy sx={{ fontSize: 56, color: 'primary.main', mb: 2 }} />
            <Typography variant="h5" gutterBottom fontWeight="bold">
              Users of AI
            </Typography>
            <Typography color="text.secondary">
              Harnessing the power of artificial intelligence to deliver innovative and intelligent solutions.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 4, textAlign: 'center', height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-5px)' } }}>
            <Security sx={{ fontSize: 56, color: 'primary.main', mb: 2 }} />
            <Typography variant="h5" gutterBottom fontWeight="bold">
              Smart and Secure
            </Typography>
            <Typography color="text.secondary">
              Building robust applications with security and intelligence at their core.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home; 